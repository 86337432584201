var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "exchangeProduct" },
    [
      _c("div", { staticClass: "exchangeProduct__title" }, [
        _vm._v("以兌換碼兌換禮物")
      ]),
      _c(
        "div",
        {
          staticClass: "exchangeProduct__btn",
          on: {
            click: function($event) {
              return _vm.openExchangePopupHandler(true)
            }
          }
        },
        [_vm._v("\n    兌換\n  ")]
      ),
      _c(
        "FixedPopup",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.openExchangePopup,
              expression: "openExchangePopup"
            }
          ],
          class: { onFocus: _vm.onFocus }
        },
        [
          _vm.exchangeResult == 0
            ? _c("div", { staticClass: "exchangeProduct__box" }, [
                _c("div", { staticClass: "exchangeProduct__box__pic" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/插圖/develop.svg"),
                      alt: ""
                    }
                  })
                ]),
                _c(
                  "div",
                  {
                    staticClass: "exchangeProduct__box__close",
                    on: {
                      click: function($event) {
                        return _vm.openExchangePopupHandler(false)
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/icon_menu-close-dark.svg")
                      }
                    })
                  ]
                ),
                _c("div", { staticClass: "exchangeProduct__box__content" }, [
                  _c("h2", [_vm._v("請輸入商品兌換碼")]),
                  _c(
                    "div",
                    {
                      staticClass: "exchangeProduct__box__content__form",
                      class: { captchaError: _vm.captchaError }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "exchangeProduct__box__content__form__input"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.exchangeCode,
                                expression: "exchangeCode"
                              }
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "請輸入兌換碼"
                            },
                            domProps: { value: _vm.exchangeCode },
                            on: {
                              focus: function($event) {
                                _vm.onFocus = true
                              },
                              blur: function($event) {
                                _vm.onFocus = false
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.exchangeCode = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.captchaError,
                                  expression: "captchaError"
                                }
                              ],
                              staticClass: "inputError"
                            },
                            [_vm._v("兌換碼不正確")]
                          )
                        ]
                      )
                    ]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "exchangeProduct__box__submit",
                    class: { notReady: !_vm.exchangeCode.length },
                    on: {
                      click: function($event) {
                        return _vm.exchangeSubmit()
                      }
                    }
                  },
                  [_vm._v("\n        兌換\n      ")]
                )
              ])
            : _c(
                "div",
                { staticClass: "exchangeProduct__result" },
                [
                  _vm.exchangeResult == 1
                    ? [
                        _c("img", {
                          staticClass: "exchangeProduct__result__img",
                          attrs: { src: require("@/assets/ok.svg"), alt: "" }
                        }),
                        _c(
                          "div",
                          { staticClass: "exchangeProduct__result__title" },
                          [
                            _vm._v(
                              "\n          已成功兌換 2024 解答之書\n        "
                            )
                          ]
                        )
                      ]
                    : _vm._e(),
                  _vm.exchangeResult == 2
                    ? [
                        _c("img", {
                          staticClass: "exchangeProduct__result__img",
                          attrs: {
                            src: require("@/assets/feeback-alert.svg"),
                            alt: ""
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "exchangeProduct__result__title" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.failMessage) +
                                "\n        "
                            )
                          ]
                        )
                      ]
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "step3Box__close",
                      on: {
                        click: function($event) {
                          return _vm.openExchangePopupHandler(false)
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@/assets/close.svg"), alt: "" }
                      })
                    ]
                  )
                ],
                2
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }