<template>
  <div class="exchangeProduct">
    <div class="exchangeProduct__title">以兌換碼兌換禮物</div>
    <div class="exchangeProduct__btn" @click="openExchangePopupHandler(true)">
      兌換
    </div>

    <FixedPopup :class="{ onFocus: onFocus }" v-show="openExchangePopup">
      <!-- 第一階段 -->
      <div class="exchangeProduct__box" v-if="exchangeResult == 0">
        <div class="exchangeProduct__box__pic">
          <img src="@/assets/插圖/develop.svg" alt />
        </div>
        <div
          class="exchangeProduct__box__close"
          @click="openExchangePopupHandler(false)"
        >
          <img src="@/assets/icon_menu-close-dark.svg" />
        </div>
        <div class="exchangeProduct__box__content">
          <h2>請輸入商品兌換碼</h2>
          <div
            class="exchangeProduct__box__content__form"
            :class="{ captchaError }"
          >
            <div class="exchangeProduct__box__content__form__input">
              <input
                type="text"
                placeholder="請輸入兌換碼"
                v-model="exchangeCode"
                @focus="onFocus = true"
                @blur="onFocus = false"
              />
              <p v-show="captchaError" class="inputError">兌換碼不正確</p>
            </div>
          </div>
        </div>
        <div
          class="exchangeProduct__box__submit"
          :class="{ notReady: !exchangeCode.length }"
          @click="exchangeSubmit()"
        >
          兌換
        </div>
      </div>

      <!-- 結果 -->
      <div class="exchangeProduct__result" v-else>
        <!-- 成功 -->
        <template v-if="exchangeResult == 1">
          <img class="exchangeProduct__result__img" src="@/assets/ok.svg" alt />
          <div class="exchangeProduct__result__title">
            已成功兌換 2024 解答之書
          </div>
        </template>
        <!-- 失敗 -->
        <template v-if="exchangeResult == 2">
          <img
            class="exchangeProduct__result__img"
            src="@/assets/feeback-alert.svg"
            alt
          />
          <div class="exchangeProduct__result__title">
            {{ failMessage }}
          </div>
        </template>
        <div class="step3Box__close" @click="openExchangePopupHandler(false)">
          <img src="@/assets/close.svg" alt />
        </div>
      </div>
    </FixedPopup>
  </div>
</template>

<script>
import FixedPopup from "../Fixed_popup";

export default {
  components: { FixedPopup },
  data() {
    return {
      captchaError: false,
      onFocus: false,
      exchangeCode: "",
      openExchangePopup: false,
      exchangeResult: 0, // 0:未兌換, 1:成功, 2:失敗
      failMessage: ``,
    };
  },
  methods: {
    openExchangePopupHandler(status) {
      if (!status && this.exchangeResult == 1) {
        //兌換成功，並關閉
        window.location.reload();
      }
      this.openExchangePopup = status;
      this.exchangeCode = "";
      this.exchangeResult = 0;
      this.failMessage = ``;
    },
    async exchangeSubmit() {
      try {
        let res = await this.$API.PATCH_ExchangeProductVoucher(
          this.exchangeCode
        );
        if (res.data.Data.ExchangedResult) {
          this.exchangeResult = 1;
        } else {
          this.exchangeResult = 2;
          this.failMessage = res.data.Data.FailMessage;
        }
      } catch (error) {
        console.error(error);
        this.exchangeResult = 2;
        this.failMessage = "網路連線失敗，請稍後再試";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.exchangeProduct {
  margin-top: 48px;
  @include mobile {
    margin-top: 28px;
  }
  &__title {
    font-size: 20px;
    color: #767676;
    margin-bottom: 16px;
    @include mobile {
      margin-bottom: 8px;
    }
  }
  &__btn {
    @include btn-default(92px, 42px, px);
    border-radius: 25px;
    margin: 0 auto;
    @include mobile {
      @include btn-default(69px, 30px, 14px);
      border-radius: 20px;
    }
  }
}

//共用
.exchangeProduct__box {
  @include rect(520px, auto, $theme-color-3, 50px);
  min-height: 510px;
  padding: 28px 36px;
  box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
    4px 4px 25px rgba(0, 0, 0, 0.3);
  font-family: $tw_font;
  @include mobile() {
    padding: 48px 36px;
    @include rect(326px, auto, $theme-color-3, 100px);
  }
  position: relative;
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    @include mobile {
      top: 32px;
      right: 32px;
      width: 40px;
      height: 40px;
    }
    img {
      width: 100%;
    }
  }
  &__pic {
    width: 225px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @include mobile {
      height: 200px;
      width: unset;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__content {
    margin: 28px auto 40px;
    @include mobile {
      margin: 28px auto 72px;
    }
    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #17445c;
      text-align: center;
    }
    &__form {
      margin: 20px auto 0;
      display: flex;
      justify-content: center;
      &.captchaError {
        input {
          border-color: #ff0000;
        }
      }
      @include mobile {
        margin: 36px auto 0;
      }
      &__input {
        width: 100%;
        max-width: 368px;
        @include mobile {
          max-width: 264px;
        }
        input {
          width: 100%;
          height: 48px;
          border-bottom: 1px solid #d8d8d8;
          padding: 0 0.5em;
          font-size: 20px;
          line-height: 24px;
          color: #17445c;
          transition: 0.2s;
          outline: none;
          &:focus {
            border-color: #17445c;
          }
          &::placeholder {
            color: #d9d9d9;
          }
          @include mobile {
            padding: 0 0.5em;
          }
        }
        .inputError {
          margin-top: 8px;
          font-size: 16px;
          line-height: 20px;
          color: #ff0000;
        }
      }
    }
  }
  &__submit {
    @include btn-default(176px, 44px, 16px);
    line-height: 30px;
    transition: 0.2s;
    margin: 0 auto;
    @include mobile {
      @include btn-default(235px, 40px, 14px);
    }
  }
}

// 結果階段
.exchangeProduct__result {
  @include rect(320px, 224px, $theme-color-3, 40px);
  box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
    4px 4px 25px rgba(0, 0, 0, 0.3);
  color: #ab956c;
  font-weight: 700;
  @include center;
  flex-direction: column;
  position: relative;
  &__img {
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
  }
  &__title {
    max-width: 256px;
    text-align: center;
  }
  &__close {
    position: absolute;
    top: 15px;
    right: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    & > img {
      width: 100%;
    }
  }
}
</style>
