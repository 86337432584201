<template>
  <div>
    <div class="page-header custom-container hidden-pad-down">
      <img class="img-title" src="@/assets/header_member.svg" alt />
      <span>我的禮物盒</span>
    </div>
    <div class="giftBox">
      <div class="giftBox__box">
        <div class="giftBox__photo">
          <div class="photo__inset">
            <div class="photo__bg">
              <v-avatar size="168" class="photo">
                <img :src="headImage" />
              </v-avatar>
            </div>
          </div>
        </div>
        <!-- 兌換碼 Start -->
        <ExchangeProduct />
        <!-- 兌換碼 End --->
        <div class="giftBox__list__title">
          <span>我的禮物總覽</span>
        </div>
        <div class="giftBox__list">
          <!-- start -->
          <div
            class="giftItemCard"
            v-for="gift in giftItemCard"
            :key="gift.GiftID"
          >
            <div class="pic_box">
              <img src="@/assets/會員相關/giftBox_item.svg" alt="" />
            </div>
            <div class="info_area">
              <div class="main_group">
                <div class="base">
                  <div class="name">{{ gift.ProductName }}</div>
                  <div class="receiverMember">
                    {{ gift.GiverName }} (ID:${{ gift.GiverMemberId }}) 贈
                  </div>
                </div>
                <div class="control">
                  <div class="control__enablefinish" v-if="gift.Enabled">
                    <img src="@/assets/歸戶平台/check.svg" alt="" />
                    已完成啟用
                  </div>
                  <div
                    class="control__enableBtn"
                    v-else
                    @click="enableGiftHandler(gift)"
                  >
                    立即啟用
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end -->
        </div>
      </div>
      <!-- end -->
    </div>
  </div>
</template>

<script>
import ExchangeProduct from "@/components/會員相關/ExchangeProduct.vue";
export default {
  components: {
    ExchangeProduct,
  },
  data() {
    return {
      giftItemCard: [
        {
          GiftID: 0,
          ProductType: 0,
          GiverMemberID: 55,
          GiverName: "林耘滔",
          ProductName: "宮位解讀",
          Enabled: false,
        },
      ],
    };
  },
  async mounted() {},
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    headImage() {
      return localStorage.imageUrl;
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let res = await this.$API.Get_GiftsList();
      this.giftItemCard = res.Data || [];
    },
    async enableGiftHandler(gift) {
      let Parameters = null;
      if (gift.ProductType == 0) {
        //此為星盤商品，需選擇對象
        this.$selectAstrolabe({
          callback: async (Astrolabe) => {
            Parameters = JSON.stringify({
              AstrolabeFileIds: [Astrolabe.AstrolabeFileId * 1],
            });
            await this.PATCH_AstrolabeFile(gift.GiftID, Parameters);
          },
        });
      } else {
        await this.PATCH_AstrolabeFile(gift.GiftID, Parameters);
      }
    },
    async PATCH_AstrolabeFile(GiftID, Parameters) {
      try {
        let res = await this.$API.PATCH_AstrolabeFile(GiftID, { Parameters });
        if (!res) throw new Error("回應失敗");
        // this.$imageNotice({
        //   imageIdx: 0,
        //   title: "啟用成功",
        //   okText: "確定",
        //   desc: `已成功啟用禮物囉!`,
        //   okEvent: () => {},
        // });
      } catch (e) {
        console.log(e);
        this.$imageNotice({
          imageIdx: 0,
          title: "啟用失敗",
          okText: "我知道了",
          desc: `服務忙線中，請稍後再試`,
          okEvent: () => {
            this.init();
          },
        });
      }
      this.init();
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/_mixin.scss";
@import "@/scss/header.scss";
@import "@/scss/toast.scss";

.giftBox {
  margin-top: 50px;
  width: 100%;
  height: auto;
  min-height: 50vh;
  box-shadow: -4px 0 12px #ffffff, -2px 0 8px #ffffff,
    4px 4px 20px rgba(0, 0, 0, 0.2);
  background-color: $theme-color-3;
  border-radius: 150px 150px 0 0;
  padding-bottom: 44px;
  @include align(center, flex-start);
  @include pad {
    border-radius: 32px 32px 0 0;
    margin-top: 95px;
  }

  .giftBox__box {
    position: relative;
    @include align(flex-start, center);
    flex-direction: column;
    position: relative;
    margin-top: -104px;
    @include mobile {
      margin-top: -60px;
    }
    @include laptop {
      width: 100%;
    }
    .giftBox__photo {
      position: relative;
      @include rect(192px, 192px, $theme-color-3, 50%);
      @include center;
      @include mobile {
        width: 120px;
        height: 120px;
      }
      .photo__inset {
        @include center;
        @include rect-inset(170px, 170px, $theme-color-3, 50%);
        @include mobile {
          width: 95px;
          height: 95px;
        }
        .photo__bg {
          @include center;
          @include rect(152px, 152px, $theme-color-3, 50%);
          @include mobile {
            width: 80px;
            height: 80px;
            .photo {
              width: 105px !important;
              height: 105px !important;
            }
          }
        }
      }
    }
    .giftBox__list__title {
      margin-top: 56px;
      width: 750px;
      font-size: 24px;
      line-height: 24px;
      color: #ab956c;
      display: flex;
      align-items: center;
      @include mobile {
        width: 100%;
        margin-top: 38px;
        font-size: 16px;
        line-height: 24px;
      }
      span {
        display: inline-block;
        margin: 0 24px;
        @include pad {
          margin: 0 20px;
        }
      }
      &:after,
      &::before {
        content: "";
        flex: 1;
        height: 1px;
        background-color: #ab956c;
        @include pad {
          margin: 0 20px;
        }
      }
    }
    .giftBox__list {
      margin-top: 40px;
      width: 500px;
      @include mobile {
        margin-top: 32px;
        width: 329px;
      }
    }
  }
}
.giftItemCard {
  width: 100%;
  margin-bottom: 44px;
  height: auto;
  background: #f0f2f4;
  box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
    4px 4px 25px rgba(0, 0, 0, 0.3);
  border-radius: 25px 50px;
  padding: 25px;
  display: flex;
  position: relative;
  transition: 0.2s;
  user-select: none;
  @include mobile {
    margin-bottom: 36px;
    height: 76px;
    padding: 14px 14px;
    border-radius: 25px 35px;
    box-shadow: -2px -2px 4px #ffffff, -2px -2px 4px #ffffff,
      4px 4px 10px rgba(0, 0, 0, 0.2);
  }
  .pic_box {
    width: 80px;
    height: 80px;
    background: #f0f2f4;
    box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
      2px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    display: flex;
    margin-right: 40px;
    flex-shrink: 0;
    @include mobile {
      width: 60px;
      height: 60px;
      position: absolute;
      left: 16px;
      top: -8px;
    }
    img {
      width: 80px;
      margin: auto;
      @include mobile {
        width: 60px;
      }
    }
  }
  .info_area {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: #9b9b9b;
    @include mobile {
      margin-left: 75px;
      letter-spacing: 0.02em;
    }
    .main_group {
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      .base {
        .name {
          color: #767676;
          font-size: 20px;
          line-height: 36px;
          font-weight: 700;
          margin-bottom: 4px;
          @include mobile {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 4px;
          }
        }
        .receiverMember {
          font-size: 14px;
          line-height: 28px;
          color: #9b9b9b;
          @include mobile {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      .control {
        &__enableBtn {
          @include rect(120px, 42px, #798da5, 100px);
          @include center;
          color: #fff;
          cursor: pointer;
          @include pad {
            @include rect(90px, 30px, #798da5, 20px);
          }
        }
        &__enablefinish {
          font-size: 16px;
          line-height: 26px;
          color: #ab956c;
          @include mobile {
            font-size: 12px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
</style>
